// pages/patient.js
import React from "react"
import { graphql　} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import List from "../components/list"
import Top from "../components/top"
import News from "../components/news"

const IndexPage = ({ data }) => (
  <Layout>
  <SEO title="Information" image={data.allMicrocmsInformation.edges[0].node.photo.url} />
  <div className="p_info contents padding">
    <div className="p_info_inner inner">
      <div className="p_page_head">
       <h1 className="p_page_head_ttl">Information</h1>
      </div>
      <News data={data.allMicrocmsInformation.edges} />
    </div>
  </div>
  </Layout>
)

export const query = graphql`
 {
  allMicrocmsInformation(sort: {fields: date, order: DESC}) {
    edges {
      node {
        id
        date
        text
        top
        photo {
          url
        }
      }
    }
  }
 }
`

export default IndexPage